import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe, LocationStrategy, PathLocationStrategy, registerLocaleData } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';

import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { BadgeModule } from 'primeng/badge';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { GalleriaModule } from 'primeng/galleria';
import { ImageModule } from 'primeng/image';
import { InplaceModule } from 'primeng/inplace';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KnobModule } from 'primeng/knob';
import { ListboxModule } from 'primeng/listbox';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { RippleModule } from 'primeng/ripple';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ScrollTopModule } from 'primeng/scrolltop';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { SkeletonModule } from 'primeng/skeleton';
import { SlideMenuModule } from 'primeng/slidemenu';
import { SliderModule } from 'primeng/slider';
import { SplitButtonModule } from 'primeng/splitbutton';
import { SplitterModule } from 'primeng/splitter';
import { StepsModule } from 'primeng/steps';
import { TabMenuModule } from 'primeng/tabmenu';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { TerminalModule } from 'primeng/terminal';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { TimelineModule } from 'primeng/timeline';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import { VirtualScrollerModule } from 'primeng/virtualscroller';

// Application Components
import { AppComponent } from './app.component';
import { AppBreadcrumbComponent } from './app.breadcrumb.component';
import { AppMainComponent } from './app.main.component';
import { AppConfigComponent } from './app.config.component';
import { AppRightMenuComponent } from './app.rightmenu.component';
import { AppInlineMenuComponent } from './app.inlinemenu.component';
import { AppMenuComponent } from './app.menu.component';
import { AppMenuitemComponent } from './app.menuitem.component';
import { AppTopbarComponent } from './app.topbar.component';
import { AppFooterComponent } from './app.footer.component';

// Application services
import { MenuService } from './services/app.menu.service';
import { AppBreadcrumbService } from './services/app.breadcrumb.service';

import { ArchiveComponent } from './pages/archive/archive.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfigService } from './services/app.config.service';
import { AppNotfoundComponent } from './pages/notFound/app.notfound.component';
import { AppErrorComponent } from './pages/error/app.error.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BookingPreviewComponent } from './pages/Shared/booking-preview/booking-preview.component';
import { DashboardModule } from './pages/dashboard/dashboard.module';
import { CustomersComponent } from './pages/customers/customers.component';
import { CsrfInterceptor } from './csrf.interceptor';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { WarehouseComponent } from './pages/warehouse/warehouse.component';

import localeDa from '@angular/common/locales/da';
import { BookingInfoComponent } from './pages/Shared/stepper/booking-info/booking-info.component';
import { AddressDialogComponent } from './pages/Shared/dialogs/address-dialog/address-dialog.component';
import { CustomerDialogComponent } from './pages/Shared/dialogs/customer-dialog/customer-dialog.component';

import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { ContactDialogComponent } from './pages/Shared/dialogs/contact-dialog/contact-dialog.component';
// import { GMapModule } from 'primeng/gmap';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { SendMailOnSubmitDialogComponent } from './pages/shipment/not-submitted-shipments/send-mail-on-submit-dialog/send-mail-on-submit-dialog.component';
import { ShipmentPreviewModule } from './pages/Shared/shipment-preview/shipment-preview.module';
import { AddressModule } from './pages/Shared/stepper/address/address.module';
import { ExchangePalletsModule } from './pages/Shared/stepper/exchange-pallets/exchange-pallets.module';
import { ColliModule } from './pages/Shared/stepper/colli/colli.module';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ServiceInfoComponent } from './pages/Shared/stepper/service-info/service-info.component';
import { AccountComponent } from './pages/Shared/stepper/account/account.component';
import { PayerComponent } from './pages/Shared/stepper/payer/payer.component';
import { DangerousGoodsComponent } from './pages/Shared/stepper/dangerous-goods/dangerous-goods.component';
import { GLSBooking } from './pages/Shared/gls-booking/gls-booking.component';
import { EdiComponent } from './pages/edi/edi.component';
import { MailMenuDialogComponent } from './pages/Shared/dialogs/mail-menu-dialog/mail-menu-dialog.component';
import { LabelDialogComponent } from './pages/shipment/not-submitted-shipments/label-dialog/label-dialog.component';
import { ExternalCarrierAddressComponent } from './pages/Shared/directives/external-carrier-address/external-carrier-address.component';
import { PostnordBookingComponent } from './pages/Shared/postnord-booking/postnord-booking.component';
import { PrintLabelShipmentDialogComponent } from './pages/Shared/dialogs/print-label-shipment-dialog/print-label-shipment-dialog.component';
import { GraphqlModule } from './graphql.module';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { ImportCustomerDialogComponent } from './pages/customers/import-customer-dialog/import-customer-dialog.component';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { ServiceTextPipe } from './pages/Shared/service-text-pipe/service-text.pipe';

registerLocaleData(localeDa);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
    GraphqlModule,
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AccordionModule,
    AutoCompleteModule,
    AvatarGroupModule,
    AvatarModule,
    BadgeModule,
    BreadcrumbModule,
    ButtonModule,
    CalendarModule,
    CardModule,
    CarouselModule,
    CascadeSelectModule,
    ChartModule,
    CheckboxModule,
    ChipModule,
    ChipsModule,
    ConfirmDialogModule,
    ConfirmPopupModule,
    ColorPickerModule,
    ContextMenuModule,
    DataViewModule,
    DialogModule,
    DividerModule,
    DropdownModule,
    FieldsetModule,
    FileUploadModule,
    GalleriaModule,
    KnobModule,
    ImageModule,
    InplaceModule,
    InputNumberModule,
    InputMaskModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    ListboxModule,
    MegaMenuModule,
    MenuModule,
    MenubarModule,
    MessageModule,
    MessagesModule,
    MultiSelectModule,
    OrderListModule,
    OrganizationChartModule,
    OverlayPanelModule,
    PaginatorModule,
    PanelModule,
    PanelMenuModule,
    PasswordModule,
    PickListModule,
    ProgressBarModule,
    RadioButtonModule,
    RatingModule,
    RippleModule,
    ScrollPanelModule,
    ScrollTopModule,
    SelectButtonModule,
    SidebarModule,
    SkeletonModule,
    SlideMenuModule,
    SliderModule,
    SplitButtonModule,
    SplitterModule,
    StepsModule,
    TableModule,
    TabMenuModule,
    TabViewModule,
    TagModule,
    TerminalModule,
    TieredMenuModule,
    TimelineModule,
    ToastModule,
    ToggleButtonModule,
    ToolbarModule,
    TooltipModule,
    TreeModule,
    TreeTableModule,
    VirtualScrollerModule,
    DashboardModule,
    DynamicDialogModule,
    // GMapModule,
    LeafletModule,
    ShipmentPreviewModule,
    AddressModule,
    ExchangePalletsModule,
    AvatarModule,
    ProgressSpinnerModule,
    TranslateModule.forRoot({
      defaultLanguage: 'da',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ReactiveFormsModule,
    ColliModule,
    NgxCsvParserModule,
    ServiceTextPipe,
  ],
  declarations: [
    AppComponent,
    AppBreadcrumbComponent,
    AppMainComponent,
    AppMenuComponent,
    AppMenuitemComponent,
    AppConfigComponent,
    AppRightMenuComponent,
    AppInlineMenuComponent,
    AppTopbarComponent,
    AppFooterComponent,
    DashboardComponent,
    AppNotfoundComponent,
    AppErrorComponent,
    ArchiveComponent,
    BookingPreviewComponent,
    CustomersComponent,
    ImportCustomerDialogComponent,
    BookingInfoComponent,
    AddressDialogComponent,
    CustomerDialogComponent,
    ContactDialogComponent,
    SendMailOnSubmitDialogComponent,
    LabelDialogComponent,
    ServiceInfoComponent,
    AccountComponent,
    PayerComponent,
    DangerousGoodsComponent,
    GLSBooking,
    EdiComponent,
    MailMenuDialogComponent,
    ExternalCarrierAddressComponent,
    PostnordBookingComponent,
    PrintLabelShipmentDialogComponent,
    WarehouseComponent,
  ],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    MenuService,
    AppBreadcrumbService,
    ConfigService,
    MessageService,
    ConfirmationService,
    DialogService,
    { provide: HTTP_INTERCEPTORS, useClass: CsrfInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'da' },
    Title,
    DatePipe,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [AddressDialogComponent, CustomerDialogComponent, SendMailOnSubmitDialogComponent, MailMenuDialogComponent, LabelDialogComponent],
})
export class AppModule {}
