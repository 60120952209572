import { Component, OnInit } from '@angular/core';
import { AppBreadcrumbService } from 'src/app/services/app.breadcrumb.service';
import { AppConfig } from 'src/app/domain/appconfig';
import { TranslateService } from '@ngx-translate/core';
import { WarehouseService } from 'src/app/services/warehouse.service';

@Component({
  selector: 'app-warehouse',
  templateUrl: './warehouse.component.html',
  styleUrls: ['./warehouse.component.scss'],
})
export class WarehouseComponent implements OnInit {
  uploadedFiles: any[] = [];
  edi: any[] = [];

  constructor(private breadcrumbService: AppBreadcrumbService,
    private warehouseService: WarehouseService,
    private translateService: TranslateService,) {
    this.breadcrumbService.setItems([{ label: 'Lager hotel' }]);
  }

  config: AppConfig;

  itemStocks: any = [];
  itemStock: any = {};

  loading: boolean = false;

  ngOnInit(): void {
    this.loaddata();
  }

  async loaddata() {
    this.warehouseService.GetItemStocks().subscribe({   //, "ecbed7aa-d57d-4fb3-a3df-a3dc0bf81e9d"
      next: (res) => {
        this.itemStocks = res;
        console.log("GetItemStocks", this.itemStocks);
      },
    });
  }

  onRowSelect(item: any) {

  }

  onRowUnselect(item: any) {

  }

  downloadCSV() {
    this.warehouseService.GetItemStocksCSV().subscribe({
      next: (res: any) => {
        if (res && res.labelUrl) {
          window.open(res.labelUrl);
        }
        //console.log("label", res);
      },
    });
  }
}
