<div class="p-fluid p-formgrid grid">
  <div class="col-12 md:col-12">
    <p-card styleClass="card">
      <ng-template pTemplate="header">
        <p-toolbar>
          <div class="p-toolbar-group-left p-card-title">
            {{'WAREHOUSE.STOCKLIST' | translate}}
          </div>
          <div class="p-toolbar-group-right">
              <p-button icon="pi pi-download" styleClass="p-button-primary" (click)="downloadCSV()"
              label="{{'CSV' | translate}}"></p-button> 
          </div>
        </p-toolbar>
      </ng-template>
      <p-table #dt1 [value]="itemStocks" dataKey="id" [rows]="10" [rowHover]="true"
        [globalFilterFields]="['itemCode','itemName']" styleClass="p-datatable-gridlines" scrollHeight="500px"
        selectionMode="single" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
        [(selection)]="itemStock" responsiveLayout="scroll" [loading]="loading">
        <ng-template pTemplate="caption">
          <div class="flex justify-content-between flex-column sm:flex-row">
            <span class="p-input-icon-left mb-2">
              <i class="pi pi-search"></i>
              <input pInputText type="text" #filter (input)="dt1.filterGlobal($event.target.value, 'contains')"
                placeholder="{{'GENERIC.SEARCH' | translate}}" class="w-full" />
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>

            <th pSortableColumn="itemCode">
              <div class="flex justify-content-between align-items-center">
                {{'WAREHOUSE.ITEMCODE' | translate}} <p-sortIcon field="itemCode"></p-sortIcon>
              </div>
            </th>

            <th pSortableColumn="itemName">
              <div class="flex justify-content-between align-items-center">
                {{'WAREHOUSE.ITEMNAME' | translate}} <p-sortIcon field="itemName"></p-sortIcon>
              </div>
            </th>
            
            <th pSortableColumn="maxAmountOnPallet">
              <div class="flex justify-content-between align-items-center">
                {{'WAREHOUSE.MAXAMOUNTPALLET' | translate}} <p-sortIcon field="maxAmountOnPallet"></p-sortIcon>
              </div>
            </th>

            <th pSortableColumn="totalAmount">
              <div class="flex justify-content-between align-items-center">
                {{'WAREHOUSE.TOTALAMOUNT' | translate}} <p-sortIcon field="totalAmount"></p-sortIcon>
              </div>
            </th>

            <th pSortableColumn="totalPalletsAmount">
              <div class="flex justify-content-between align-items-center">
                {{'WAREHOUSE.TOTALPALLETAMOUNT' | translate}} <p-sortIcon field="totalPalletsAmount"></p-sortIcon>
              </div>
            </th>

          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-itemStock>
          <tr [pSelectableRow]="itemStock">
            <td style="min-width: 12rem;">
              {{itemStock.itemCode}}
            </td>
            <td style="min-width: 12rem;">
              {{itemStock.itemName}}
            </td>
            <td class="text-center">
              {{itemStock.maxAmountOnPallet}}
            </td>
            <td class="text-center">
              {{itemStock.totalAmount}}
            </td>
            <td class="text-center">
              {{itemStock.totalPalletsAmount}}
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="4">{{'NOCUSTOMERSFOUNDMESSAGE' | translate}}</td>
          </tr>
        </ng-template>

        <ng-template pTemplate="loadingbody">
          <tr>
            <td colspan="4">{{'FETCHINGCUSTOMERSMESSAGE' | translate}}</td>
          </tr>
        </ng-template>

      </p-table>
    </p-card>
  </div>
</div>