import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ClientService } from './client.service';

@Injectable({
  providedIn: 'root',
})
export class WarehouseService {
  constructor(private httpClient: HttpClient, private client: ClientService) {}

  GetItemStocks() {
    return this.httpClient.get(`${environment.apiUrl}/warehouse/itemstocks/getforclient?clientId=${this.client.clientId}`);
  }

  GetItemStocksCSV() {
    return this.httpClient.get(`${environment.apiUrl}/warehouse/itemstocks/getforclientcsv?clientId=${this.client.clientId}`);
  }
}
